
import { defineComponent, onMounted, inject, watch, ref, reactive } from "vue";

import dateFormat from "../../utils/dateFormat";
import { calendarFormat} from "@/utils/dateFormat";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
interface CustomObject {
  [key: string]: any;
}

export default defineComponent({
  name: "InvoiceItem",
  props: {
    invoiced: Object
  },

  setup(props) {
    const invoice = reactive((props.invoiced as any).data || [])
    function neiapplyExpressTrace(piaopost: string,piaomail: string, index: any){
      const formData = new FormData();
      formData.append("piaopost", piaopost.valueOf());
      formData.append("piaomail", piaomail.valueOf());
      axios
        .post('/M/Server/neiApplyExpressTrace', formData, {params: { validate: true }})
        .then((res) => {
          if (res.data.success) {
            if (!res.data.obj.success) {
              Toast({
                type: "error",
                title: res.data.msg,
              });
              return false
            }
            invoice[index].traces = res.data.obj
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: "系统出错，请联系管理员!",
          });
        });
    }
    function onDownLoad(path: string) {
      axios
        .post(`/M/Server/CheckInvoiceExist?filename=${path}`, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            window.location.href = res.data.str
            // window.open(res.data.str);
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: "发票下载出错，请联系管理员",
          });
        });
    }

    return {
      dateFormat,
      calendarFormat,
      neiapplyExpressTrace,
      invoice,
      onDownLoad,
    };
  }
});
