<template>
  <transition-group tag="ul" name="list" class="car-ul">
    <li class="car-li" v-for="(item, index) in invoice" :key="index">
      <div class="order-date">
        发票金额：{{ item.piaovalue ? item.piaovalue : "暂无" }}
      </div>
      <div class="order-date">
        发票类型：{{
          item.piaotype
            ? item.electronic
              ? "电子" + item.piaotype
              : "纸质"+ item.piaotype
            : "暂无"
        }}
      </div>
      <!--<div class="order-date">
        发票税率：{{item.taxrate?(item.taxrate*100)+"%":"暂无"}}
      </div>-->
      
      <div class="order-date">
        发票抬头：{{ item.userunit}}
      </div>
      <div class="order-date">
        <span v-if="item.filepath && item.electronic == true">已开具</span>
        <span v-if="item.piaopost && item.piaomail">
          快递信息：{{ item.piaopost }} {{ item.piaomail }}
        <span class="order-logistics"
              @click="neiapplyExpressTrace(item.piaopost,item.piaomail, index)">查看物流</span>
        </span>
        <span v-if="!item.filepath && !item.piaopost && !item.piaomail">暂无</span>
      </div>
      <div class="logistics" v-if="item.traces">
        <p v-if="item.traces.reason">
          <span class="logistics-notyet">{{ item.traces.reason }}</span>
        </p>

        <div v-else class="nei-order-wuliu">
          <ul v-if="item.traces">
            <li v-for="(itm, i) in item.traces.traces" :key="i">
            <span class="orderDetail_accept"
            >
              {{ itm.acceptTime.substring(0,16)}}
              {{ itm.acceptStation }}
            </span
            >
            </li>
          </ul>
        </div>
      </div>
      <div class="spend-date">
        <div v-if="item.askdate">
          <span>{{ calendarFormat(item.askdate)[2] }}</span>
          <p>
            {{ calendarFormat(item.askdate)[0] }}/{{ calendarFormat(item.askdate)[1] }}
          </p>
        </div>
      </div>

      <div
        class="download"
        v-if="
          item.filepath &&
          (item.filepath.indexOf('.pdf') != -1 ||
            item.filepath.indexOf('.doc') != -1)
        "
      >
        <a
          href="javaScript:void(0);"
          @click="onDownLoad(item.filepath)"
          id="download"
          >发票下载</a
        >
      </div>
    </li>
  </transition-group>
</template>



<script lang="ts">
import { defineComponent, onMounted, inject, watch, ref, reactive } from "vue";

import dateFormat from "../../utils/dateFormat";
import { calendarFormat} from "@/utils/dateFormat";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
interface CustomObject {
  [key: string]: any;
}

export default defineComponent({
  name: "InvoiceItem",
  props: {
    invoiced: Object
  },

  setup(props) {
    const invoice = reactive((props.invoiced as any).data || [])
    function neiapplyExpressTrace(piaopost: string,piaomail: string, index: any){
      const formData = new FormData();
      formData.append("piaopost", piaopost.valueOf());
      formData.append("piaomail", piaomail.valueOf());
      axios
        .post('/M/Server/neiApplyExpressTrace', formData, {params: { validate: true }})
        .then((res) => {
          if (res.data.success) {
            if (!res.data.obj.success) {
              Toast({
                type: "error",
                title: res.data.msg,
              });
              return false
            }
            invoice[index].traces = res.data.obj
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: "系统出错，请联系管理员!",
          });
        });
    }
    function onDownLoad(path: string) {
      axios
        .post(`/M/Server/CheckInvoiceExist?filename=${path}`, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            window.location.href = res.data.str
            // window.open(res.data.str);
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: "发票下载出错，请联系管理员",
          });
        });
    }

    return {
      dateFormat,
      calendarFormat,
      neiapplyExpressTrace,
      invoice,
      onDownLoad,
    };
  }
});
</script>


<style lang="scss" scoped>
main {
  padding-bottom: 50px;
}

.car-li {
  box-sizing: border-box;
  padding: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  border-bottom: 5.625px solid #f4f4f4;
  position: relative;
  padding-left: 70px;
  margin-right: 0;
}

.car-li .checkbox {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 10px;
  top: 50%;
  margin-top: -10px;
  z-index: 10;
}
.car-li .checkbox .car-check {
  position: absolute;

  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  z-index: 10;
  opacity: 0;
}

.order-date {
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 0 5px 0;
  padding-left: 10px;
  font-size: 14px;
  box-sizing: border-box;
  color: #666;
  position: relative;
}

.spend-date {
  position: absolute;
  left: 15px;
  // top: 20px;
  top: 38px;
  color: #666;
}
.spend-date span {
  font-size: 21.938px;
  text-align: center;
  display: block;
}
.spend-date p {
  font-size: 13.95px;
  border-top: 1px solid #e5e5e5;
  padding-top: 5px;
}
#download {
  position: absolute;
  right: 15px;
  top: 50px;
  text-align: center;
  width: 80px;
  height: 22.5px;
  border-radius: 15px;
  line-height: 22.5px;
  font-size: 14px;
  color: #df0034;
  border: solid 1px #df0024;
}
.car-li .checkbox img {
  position: absolute;
  width: 20px;
  height: 20px;
  vertical-align: top;
  left: 0;
  top: 0;
  z-index: 5;
  display: none;
}
.car-li .checkbox::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  border: 1px solid #ccc;
  border-radius: 2.513px;
  box-sizing: border-box;
}
.car-li .product-pic img {
  width: 75px;
  height: 75px;
  position: absolute;
  left: 48.487px;
  top: 50%;
  margin-top: -37.5px;
}

.product-info .product-name {
  width: 16em;
  display: block;
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-info .product-no {
  font-size: 13px;
  color: #999;
  width: 16em;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.poroduct-num {
  margin-top: 5px;
}
.poroduct-num .input {
  display: inline-block;
  border: 1px solid #eee;
  height: 25px;
  vertical-align: middle;
  box-sizing: border-box;
}
.poroduct-num input {
  width: 50px;
  height: 20px;
  outline: none;
  border: none;
  vertical-align: top;
  padding-top: 5px;
  box-sizing: border-box;
  color: #666666;
  text-align: center;
}
.poroduct-num .sub {
  display: inline-block;
  position: relative;
  left: 7.987px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  vertical-align: middle;
  background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d13.png")
    no-repeat center center;
}
.poroduct-num .add {
  display: inline-block;
  position: relative;
  left: -7.987px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  vertical-align: middle;
  background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d14.png")
    no-repeat center center;
  background-size: 10px 10px;
}

.invoice {
  .car-li {
    padding-left: 15px;
    display: flex;
    align-items: center;
    v::deep {
      .ui-checkbox__label {
        display: flex;
        align-items: center;
      }
    }
  }
  .spend-date {
    left: 42px;
    position: static;
  }
}

:deep(.zm-checkbox__label) {
  display: flex;
  align-items: center;
  .spend-date span {
    line-height: 29px;
  }
}

.dashboard {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 50px;
  font-size: 14px;
  border-top: 1px solid #f4f4f4;
  line-height: 50px;
  color: #666;
  padding-left: 15px;

  :deep(.zm-checkbox__label) {
    display: inline-block;
  }
}
.order-logistics{
  display: block;
  width: 80px;
  height: 25px;
  background: #ffeded;
  text-align: center;
  color: #df0024;
  line-height: 25px;
  /* float: right; */
  margin-top: 10px;
  border: 1px solid #df0024;
  border-radius: 20px;
  font-size: 14px;
}
.pay {
  position: absolute;
  right: 10px;
  top: 0;
  width: 100px;
  height: 35px;
  background-color: #d64b4b;
  color: #fff;
  border-radius: 35px;
  text-align: center;
  line-height: 35px;
  top: 50%;
  margin-top: -17.5px;
}
.pay a {
  color: #fff;
}
.nei-order-wuliu ul li{
  line-height: 20px;
}
  .orderDetail_accept {
    font-size: 14px;
    color: #999;
  }
    .logistics-notyet{
      font-size: 16px!important;
      color:#df0024;
    }

</style>
